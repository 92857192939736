import Vue from 'vue'
import App from './App'
import router from './router'
import Fragment from 'vue-fragment'
import store from '@/store'
import Vuelidate from 'vuelidate'
import Notifications from 'vue-notification'
import BootstrapVue from 'bootstrap-vue'
import globals from './globals'
import Popper from 'popper.js'
import VCalendar from 'v-calendar'

import TokenService from '@/services/shared/storage.service'
import { AuthApiService } from '@/services/apis/auth.api.service'
import { DataApiService } from '@/services/apis/data.api.service'

// NProgress
import '../node_modules/nprogress/nprogress.css'
import '../node_modules/vue2-timeago/dist/vue2-timeago.css'

// vue-socket.io
import VueSocketIO from 'vue-socket.io'

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)

// Source: https://vcalendar.io/installation.html#npm
Vue.use(VCalendar, {
  locales: {
    'pt-BR': {
      firstDayOfWeek: 1,
      masks: {
        L: 'YYYY-MM-DD'
        // ...optional `title`, `weekdays`, `navMonths`, etc
      }
    }
  }
})

// Socket.io
Vue.use(new VueSocketIO({
  debug: false,
  connection: process.env.VUE_APP_SOCKET,
  vuex: {
    store,
    actionPrefix: 'SOCKET_'
  },
  options: { autoConnect: false } // Optional options
}))

// Source: https://github.com/euvl/vue-notification/
Vue.use(Notifications)

// Source: https://www.npmjs.com/package/vue-fragment
Vue.use(Fragment.Plugin)

// Global RTL flag
Vue.mixin({
  data: globals
})

// Source: https://vuelidate.js.org/
Vue.use(Vuelidate)

// Sources:
// https://github.com/aiyazkhorajia/vue-boilerplate

// If token exists set header
// If token exists set header
if (TokenService.getToken()) {
  // Set headers (JWT)
  AuthApiService.setHeader()
  DataApiService.setHeader()

  // Set Interceptors (401 -> Redirect)
  AuthApiService.mount401Interceptor()
  DataApiService.mount401Interceptor()
}

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
