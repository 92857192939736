// Application
export const APP_HOME_URL = '/'

// Authentication
export const AUTH_LOGIN_URL = '/api/auth/login/'
export const AUTH_REGISTER_URL = '/api/auth/register'
export const AUTH_RESET_PASSWORD_URL = '/api/auth/reset'

// Suppliers
export const SUPPLIER_URL = '/api/suppliers'
export const SUPPLIER_DETAIL_URL = '/api/suppliers/:id'
export const SUPPLIER_NOTE = '/api/suppliers/notes/:id'
export const SUPPLIER_CONTACT = '/api/suppliers/contacts/:id'
export const SUPPLIER_ADDRESS = '/api/suppliers/addresses/:id'

// User
export const USER_CHANGE_PASSWORD_URL = '/users/change'
export const USER_REFRESH_TOKEN_URL = '/users/refresh'
export const USER_SUBSCRIPTIONS_URL = '/users/subcriptions'

// ZipCode
export const ZIPCODE_URL = '/api/zipcode/:id'

// File Types
export const FILETYPE_URL = '/api/file/filetypes'
export const DOCUMENTTYPE_URL = '/api/file/documenttypes'
export const FILE_URL = '/api/file/:id'
export const DELETE_FILE_URL = '/api/file/supplier/:id'
export const FILE_DOWNLOAD_URL = '/api/file/download/:id'
